import './_loading.scss';

function Loading(){
    return(
        <div className="container-loading">
     
            <div className='circle circle-1'></div>
            <div className='circle circle-2'></div>
            <div className='circle circle-3'></div>
            
        </div>
    )
}

export default Loading;