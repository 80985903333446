import { useContext, useEffect, useRef, useState, React } from 'react';
import { AuthContext } from '../../contexts/auth';
import Input from '../input/Input';
import './_formLogin.scss';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import logoAbrafati from '../../assets/logo-abrafati.png';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../button/button';

function FormLogin({ revertLayout }) {
  const [usePassword, setUsePassword] = useState('password')
  const { getLogin, showAlertDatasLogin, messageLogin } = useContext(AuthContext)
  const navigate = useNavigate()
  const emailRef = useRef(null)
  const senhaRef = useRef(null)

  const { search } = useLocation();

  function visiblePassword() {
    const password = document.querySelector('#senha')
    if (password.type === 'password') {
      password.type = 'text'
      setUsePassword('text')
    } else {
      password.type = 'password'
      setUsePassword('password')
    }
  }

  useEffect(() => {
    function require() {
      if (emailRef.current.value.length < 1) return


      if (showAlertDatasLogin) {
        let alertRequire = document.querySelectorAll('.required')
        alertRequire[1].style.display = "block";
      }
    }

    require()

  }, [showAlertDatasLogin])

  useEffect(() => {
    let queryParams = new URLSearchParams(search);
    let email = queryParams.get('email');
    let token = queryParams.get('token');

    if (token != null) {
      getLogin(email, 'senha', token);
    }
  }, []);

  function sendLogin(e){
    e.preventDefault()

    getLogin(emailRef.current.value, senhaRef.current.value)
  }

  return (
    <div className='login__box-left'>

      <div className='box-apresentation'>
        <h2 className='title'>Seja bem vindo</h2>
        <p className='subtitle'>Por favor, coloque suas informações.</p>
      </div>

      <form onSubmit={e => sendLogin(e)}>
        <Input useRef={emailRef} key='email' type='text' id="email" label="E-mail" placeholder="Digite seu e-mail" />
        <Input useRef={senhaRef} key='senha' text={messageLogin} type='password' id="senha" label="Senha" placeholder="Digite sua senha" />

        {usePassword === 'text' ? <AiOutlineEye onClick={visiblePassword} className='logo-invisible' /> : <AiOutlineEyeInvisible onClick={visiblePassword} className='logo-invisible' />}

        <div className='box-interation'>
          <span className='cadastro' onClick={() => navigate('/cadastro')}>Ou cadastre-se</span>

          <Button type='submit'>
            Entrar
          </Button>

          <div className='box-renovar-senha'>
            <p>Esqueceu sua senha?</p>
            <span onClick={revertLayout} className='link-renovar-senha'>Clique aqui</span>
          </div>
        </div>
        <img className='logo-abrafati' src={logoAbrafati} alt='logomarca Abrafati' />
      </form>

    </div>
  )
}
export default FormLogin;