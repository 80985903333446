import CardPerguntasResultado from '../cardPerguntasResultado/cardPerguntasResultado';
import './_pageResultadoTema.scss';

function PageResultadoTema({title, subthemes, idTema}){

    return(
        <div className="container-resultado-tema">
            <h2 className={`resultado-tema__tema-title color-tema${idTema}`}>{title}</h2>

            {
                subthemes.map(el => {
                    return <CardPerguntasResultado key={el.id} title={el.title} questions={el.questions} idTema={idTema}/>
                })
            }

        </div>
    )
}
export default PageResultadoTema;