import { useEffect, useRef } from 'react';
import './_subTema.scss';
import {MdChevronRight} from "react-icons/md";
import { useContext } from 'react';
import { AdminContext } from '../../contexts/temasAdminContext';


function CardSubTema({title, progress, redirectPageQuestionario, id}){
    const progressBarRef = useRef(null)  
    const {setSubTemaId} = useContext(AdminContext)

    function executeFunctions(){
        redirectPageQuestionario()
        setSubTemaId(`title-${id}`)
    }

    useEffect(() => {
        progressBarRef.current.style.background = `conic-gradient(#ffffff ${(100 - progress) * 3.6}deg, #2AD3AA ${(100 - progress)* 3.6}deg)`
    }, [progress])   

    return(
        <div onClick={executeFunctions} className="box-subtema">
            <p>
                {title}
            </p>
            <div ref={progressBarRef} className="grafico-rosca-resposta">
                <div className="value-progress">{progress}%</div>
            </div> 
            <MdChevronRight className='icon-iteration'/>
        </div> 
    )
}

export default CardSubTema;