import './_cardDowload.scss';
import manual from '../../assets/img-card-relatorio.png';
import { useNavigate } from 'react-router-dom';

function CardDowload({title, ano}){
    const navigate = useNavigate()

    return(
        <div className="card-dowload">
            <img src={manual} alt="img"/>
            <h4>{title}</h4>
            
            <span onClick={() => navigate('/relatorio-conjunto')} className='btn-dowload' download={`relatorio-${ano}`} type="application/pdf" >
                Download
            </span>
        </div>
    )
}

export default CardDowload;