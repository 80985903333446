import { Link } from "react-router-dom";
import {GoAlert} from 'react-icons/go'
import './_cardPopUp.scss';


function CardPopUp(){
    return(
        <div className="container-pop-up">
            <h3>Não conseguimos gerar seu relatório no momento, é necessário responder todos os indicadores!</h3>

            <Link className="btn-pop" to='/' >Continuar</Link>

            <GoAlert className="icon-alert"/>
        </div>
    )
}

export default CardPopUp;