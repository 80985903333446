import { Navigate, Route, Routes } from "react-router-dom";
import Dowloads from "../pages/dowloads/dowloads";
import Temas from "../pages/layout-temas/temas";
import Manual from "../pages/manual/manual";
import Perguntas from "../pages/perguntas/perguntas";
import Relatorio from "../pages/relatorio/relatorio";
import Privacidade from "../pages/politica-de-privacidade/privacidade";
import Header from "../components/headerLayout/headerLayout";
import MenuAside from "../components/menu-aside/menu-aside";
import RelatorioConjunto from "../pages/relatorioConjunto/relatorioConjunto";
import Arquivos from "../pages/Arquivos/Arquivos";


function AppRoutes(){
    return(
        <>
            <MenuAside />
            <Header/>
            
            <Routes>      
                <Route exact path="/" element={<Temas/>}/>
                <Route exact path="/questionario/:id" element={<Perguntas/>}/>
                <Route exact path="/relatorio" element={<Relatorio/>}/> 
                <Route exact path="/manual" element={<Manual/>}/> 
                <Route exact path="/downloads" element={<Dowloads />}/> 
                <Route exact path="/politica-de-privacidade" element={<Privacidade/>}/>
                <Route exact path="/cadastro3" element={<Navigate to='/' replace/>}/>
                <Route exact path="/relatorio-conjunto" element={<RelatorioConjunto/>}/>
                <Route exact path="/arquivos/:id/:name" element={<Arquivos/>}/> 
 
            </Routes> 
        </>
    )
}

export default AppRoutes;