import { Autocomplete, TextField } from '@mui/material';
import './_header.scss';
import { useEffect, useState } from 'react';
import { HiOutlineUser } from 'react-icons/hi'
import { AuthContext } from '../../contexts/auth';
import { useContext } from 'react';

function Header(){
    const { cyrcleStorage, setCyrcleStorage } = useContext(AuthContext)
    const emailUser = JSON.parse(localStorage.getItem('@user')).email
    const nameUser = JSON.parse(localStorage.getItem('@user')).company_data?.company
    const [imagePost, setImagePost] = useState(JSON.parse(localStorage.getItem('@user')).src_photo)
    const [cycles, setCycles] = useState(false)

    useEffect(() => {
        const ciclysAkatu = JSON.parse(localStorage.getItem("@ciclysAkatu"))

        ciclysAkatu.forEach(el => {
            el.label = el.cycle
            delete el.cycle
        });

        setCycles(ciclysAkatu)
    },[])

    useEffect(() => {
        const pictureBoxImage = document.querySelector(".picture-box-image")

        if(imagePost === null) return

        const img = document.createElement('img')
        img.src = imagePost
        img.classList.add('picture-img')
        pictureBoxImage.innerHTML = ''
        pictureBoxImage.appendChild(img)

    },[imagePost])

    function postFileInput(e){
        const headers = {
            "Authorization": `Bearer ${localStorage.getItem('@token')}`,
            // "Content-Type": "multipart/form-data",
            "Accept": "application/json",
        };

        let formData = new FormData()
        formData.append('photo', e.target.files[0])

        fetch(`${process.env.REACT_APP_API_URL}/profile/update`, {
            method: "POST",
            headers,
            body: formData
        })
        .then(response => response.json())
        .then(response => {
            localStorage.setItem('@user', JSON.stringify(response.data))
            setImagePost(JSON.parse(localStorage.getItem('@user')).src_photo)
        })
    }

    function chooseCyrcleAkatu(idCyrcle){
        setCyrcleStorage(idCyrcle)
        localStorage.setItem("@cycleChosed", JSON.parse(idCyrcle))
    }

    return(
        <div className='box-apresentacao-empresa'>
                <div className="box-information">
                    <div className="box-info-user">
                        <p className='apresentacao__name-empresa'>{nameUser}</p>
                        <p className='email-user' >{emailUser}</p>
                    </div>

                    {
                        cycles && (
                            <Autocomplete
                            disablePortal
                            disableClearable
                            defaultValue={cycles.length > 1 ? cycles[cyrcleStorage-1] : cycles[0]}
                            id="combo-box-demo"
                            options={cycles}
                            sx={{ width: 200 }}
                            size='small'
                            onChange={(event, value) => chooseCyrcleAkatu(value.quiz_id)}
                            renderInput={(params) => <TextField {...params} label="Ciclo acessado" />}
                            />
                        )
                    }
                </div>

                <label className="picture-company">
                    <input onChange={postFileInput} className='picture-input' id='picture-input' type="file" accept='image/*' />
                    <span tooltip='Editar imagem' className="picture-box-image">
                        {
                            !imagePost && <HiOutlineUser/>
                        }
                    </span>
                </label>
        </div>
    )
}

export default Header;
