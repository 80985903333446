import './_cardTema.scss';
import CardSubTema from '../cardSubTema/cardSubTema';
import { AdminContext } from '../../contexts/temasAdminContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';


function CardTema({ title, introduction, image, temaColor, temaBg, progress, subthemes, idTema}){
    const {setSubTema, setSubTemaId} = useContext(AdminContext)
    const navigate = useNavigate()


    function redirectPageQuestionario(){
        setSubTema(idTema)
        navigate(`/questionario/${idTema}`)
    }

    function buttonAnchor(){
        setSubTema(idTema)
        setSubTemaId('')
        navigate(`/questionario/${idTema}`)
    }
   
    return(
        <div className="card-tema">
            <div className="card-tema__box-left">
                <div className='box-img-card-tema'>
                    <img src={image} alt=""/>
                </div>

                <h2 id={temaColor}>{title}</h2>

                <p className='card-tema__description' dangerouslySetInnerHTML={{__html: introduction}}></p>
            </div>

            <div className="card-tema__box-rigth">

                <div className="box-percentual-concluido">
                    <h2>{progress}%</h2>
                    <p>Concluído</p>
                    <progress value={progress}  max='100'></progress>
                </div>

                {
                    subthemes.map(el => <CardSubTema key={el.id} redirectPageQuestionario={redirectPageQuestionario} id={el.id} title={el.title} progress={Math.round(el.progress)} />)
                }

                <button onClick={buttonAnchor} className='card-tema__btn' id={temaBg}>Continuar</button>
            </div>
        </div>
    )
}

export default CardTema;