import './_login.scss';
import { useEffect, useState } from 'react';
import RenewPassword from '../../components/formRenewPassword/formRenewPassword';
import FormLogin from '../../components/formLogin/formLogin';


function Login(){
  const [showNewPassword, setShowNewPassword] = useState(false)

  useEffect(() => {
    document.title = 'Abrafati - Login'
  }, [])
 
  function revertLayout(){
    setShowNewPassword(!showNewPassword)
  }
  
  return(
    <section className='container-login'>


      {
        showNewPassword ? <RenewPassword revertLayout={revertLayout}/> : <FormLogin revertLayout={revertLayout} />
      }
      
    </section>
  )
}

export default Login;



