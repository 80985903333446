import './_privacidade_publico.scss'
import Logo from '../../assets/logo-abrafati-menu.svg';
import { useEffect } from 'react';


function Privacidade() {

    useEffect(() => {
        document.title = 'Abrafati - Política de privacidade'
    
    }, [])

    return(
        <section className='politica-privacidade'>
            <nav>
                <img src={Logo} alt="" className='logo-menu' />
            </nav>
            <div className='conteudo'>
                <div className="container">
                    <h1>Política de Privacidade</h1>
                    <div className='setor'>
                        <h3>O QUE FAREMOS COM ESTA INFORMAÇÃO?</h3>
                        <p>Quando você nos envia alguma mensagem, coletamos as informações pessoais que você nos dá tais como: nome, e-mail e telefone.<br/><br/>
                        Quando você acessa nosso site, também recebemos automaticamente o protocolo de internet do seu computador, endereço de IP, a fim de obter informações que nos ajudam a aprender sobre seu navegador e sistema operacional.<br/><br/>
                        Email Marketing será realizado apenas caso você permita ou assine nossa newsletter. Nestes emails você poderá receber notícia e informações sobre a ABRAFATI.</p>
                    </div>
                    <div className='setor'>
                        <h3>CONSENTIMENTO</h3>
                        <p>Como vocês obtêm meu consentimento?<br/><br/>
                        Quando você fornece informações pessoais como nome, telefone e endereço, para enviar uma mensagem. Após a realização de ações entendemos que você está de acordo com a coleta de dados para serem utilizados pela nossa empresa.
                            <br/><br/>
                        Se pedimos por suas informações pessoais por uma razão secundária, como marketing, vamos lhe pedir diretamente por seu consentimento, ou lhe fornecer a oportunidade de dizer não.
                            <br/><br/>
                        E caso você queira retirar seu consentimento, como proceder?
                            <br/><br/>
                        Se após você nos fornecer seus dados, você mudar de ideia, você pode retirar o seu consentimento para que possamos entrar em contato, para a coleção de dados contínua, uso ou divulgação de suas informações, a qualquer momento, entrando em contato conosco em <span><a href="mailto:contato@abrafati.com.br">contato@abrafati.com.br </a></span>ou nos enviando uma correspondência em: Abrafati Av. Dr. Cardoso de Melo, 1340 – Cj. 131 Vila Olímpia – São Paulo – SP</p>
                    </div>
                    <div className='setor'>
                        <h3>DIVULGAÇÃO</h3>
                        <p>Podemos divulgar suas informações pessoais apenas caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.</p>
                    </div>
                    <div className='setor'>
                        <h3>TERCEIROS</h3>
                        <p>Não disponibilizamos seus dados a nenhum terceiro.</p>
                    </div>
                    <div className='setor'>
                        <h3>SEGURANÇA</h3>
                        <p>Para proteger suas informações pessoais, tomamos precauções razoáveis e seguimos as melhores práticas da indústria para nos certificar que elas não serão perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas ou destruídas.
                        <br/><br/>
                        Se você nos fornecer as suas informações de senha, essa informação é criptografada usando tecnologia “secure socket layer” (SSL) e armazenada com uma criptografia AES-256. Embora nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos padrões adicionais geralmente aceitos pela indústria.</p>
                    </div>
                    <div className='setor'>
                        <h3>ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h3>
                        <p>Reservamos o direito de modificar essa política de privacidade a qualquer momento, então por favor, revise-a com frequência. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação no site. Se fizermos alterações de materiais para essa política, iremos notificá-lo aqui que eles foram atualizados, para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se alguma, usamos e/ou divulgamos elas.
                        <br /><br />
                        Se nossa associação for adquirida ou fundida com outra associação, suas informações podem ser transferidas para os novos proprietários para que possamos continuar a divulgar novidades para você.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Privacidade;