const { createContext, useState, useEffect } = require("react");

export const AdminContext = createContext()


function AdminContextProvider({children}){
    const [subTema, setSubTema] = useState(0)
    const [subTemaId, setSubTemaId] = useState(2)


    useEffect(() => {
        const elementRoot = document.documentElement

        if(subTema === 1){
            elementRoot.style.setProperty('--color-tema', '#E5918B')
        }

        if(subTema === 2){
            elementRoot.style.setProperty('--color-tema', '#5B97DB')
        }

        if(subTema === 3){
            elementRoot.style.setProperty('--color-tema', '#FCC525')
        }

        if(subTema === 4){
            elementRoot.style.setProperty('--color-tema', '#83B536')
        }
    },[subTema])
    
    return(
        <AdminContext.Provider value={{subTema, setSubTema, subTemaId, setSubTemaId}} >
            {children}
        </AdminContext.Provider>
    )
}

export default AdminContextProvider;