import { useContext, useEffect, useRef, useState } from "react";
import PageResultadoTema from "../../components/pageResultadoTema/pageResultadoTema";
import { AuthContext } from "../../contexts/auth";
import logoAbrafati from "../../assets/logo-abrafati.png";
import "./_relatorioConjunto.scss";
import axios from "axios";
import configeToken from "../../services/api";
import CardRelatorio from "../../components/cardRelatorio/cardRelatorio";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import imgGraph from "../../assets/bg-grafico22__3_.png";
import Loading from "../../components/loading/loading";
import { useNavigate } from "react-router-dom";
HighchartsMore(Highcharts);

function RelatorioConjunto() {
    const printRef = useRef(null);
    const nameUser = JSON.parse(localStorage.getItem("@user")).company_data
        .company;
    const { updateToken, logoutLogin } = useContext(AuthContext);
    const [dataTema1, setDataTema1] = useState([]);
    const [dataSubthemes1, setDataSubthemes1] = useState([]);
    const [dataTema2, setDataTema2] = useState([]);
    const [dataSubthemes2, setDataSubthemes2] = useState([]);
    const [dataTema3, setDataTema3] = useState([]);
    const [dataSubthemes3, setDataSubthemes3] = useState([]);
    const [dataTema4, setDataTema4] = useState([]);
    const [dataSubthemes4, setDataSubthemes4] = useState([]);
    const [dataTemasRelatorio, setDataTemasRelatorio] = useState(false);
    const [options, setOptions] = useState({});
    const [stageCompany, setStageCompany] = useState({});
    const {
        percent,
        score,
        total_score,
        sector,
        percent_attendance,
        banchmark,
        banchmark_percent_attendance,
    } = dataTemasRelatorio;
    const { id, name, title, description } = stageCompany;
    const [showLoading, setShowLoading] = useState(false);
    const cyrcleStorage = JSON.parse(localStorage.getItem("@cycleChosed"));
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Abrafati - Relatório geral";
    }, []);

    useEffect(() => {
        let width = window.innerWidth;
        let mySVG = document.querySelector(".highcharts-root");
        if (mySVG === null) return;

        if (Number(width) > 1440) {
            mySVG.setAttribute("viewBox", "0 0 800 600");
        }
    }, [options]);

    function getLegend(value) {
        if (value < 21.17) {
            return "Cadeia de fornecimento sustentável &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        } else if (value < 21.17 * 2) {
            return "Comunidade e&nbsp;sociedade";
        } else if (value < 21.16 * 3) {
            return "Relacionamento com&nbsp;revendores e&nbsp;profissionais de&nbsp;pintura";
        } else if (value < 21.17 * 4) {
            return "Transparência e informação para o consumidor";
        } else if (value < 21.16 * 5) {
            return "Gestão de Resíduos Industriais e Materiais Perigosos";
        } else if (value < 21.16 * 6) {
            return "Qualidade do Ar e Emissões";
        } else if (value < 21.16 * 7) {
            return "Energia";
        } else if (value < 21.16 * 8) {
            return "Água e Efluentes";
        } else if (value < 21.16 * 9) {
            return "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Uso eficiente de materiais e gestão do ciclo de vida";
        } else if (value < 21.16 * 10) {
            return "Desenvolvimento, educação e fortalecimento para a ciência";
        } else if (value < 21.16 * 11) {
            return "Diversidade e Inclusão";
        } else if (value < 21.16 * 12) {
            return "Saúde, Segurança e Qualidade de Vida do Trabalhador";
        } else if (value < 21.16 * 13) {
            return "Relações Trabalhistas";
        } else if (value < 21.16 * 14) {
            return "Governança Corporativa";
        } else if (value < 21.16 * 15) {
            return "Ética e Integridade";
        } else if (value < 21.16 * 16) {
            return "Modelo de negócios e Inovação";
        } else {
            return "Saúde e Segurança do Consumidor  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        }
    }

    function setOptionsChart(subthemes) {
        let array = [];
        let benchmark = [];

        subthemes.map((subtheme) => {
            array.push({ y: subtheme.value, color: subtheme.color });
            benchmark.push({
                y: subtheme.benchmark,
                color: subtheme.color,
            });
        });

        return {
            chart: {
                polar: true,
                width: 700,
                height: 600,
                backgroundColor: "transparent",
            },
            title: {
                text: "Highcharts Polar Chart",
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                size: "90%",
            },
            tooltip: {
                formatter: function (tooltip) {
                    return subthemes[this.point.index].stage.description;
                },
                // enabled: false
            },
            xAxis: {
                tickInterval: 21.17,
                min: 0,
                max: 360,
                labels: {
                    enabled: true,
                    formatter: function () {
                        return getLegend(this.value);
                    },
                },
            },
            plotOptions: {
                series: {
                    pointStart: 0,
                    pointInterval: 21.17,
                },
                column: {
                    pointPadding: 0,
                    groupPadding: 0,
                },
                line: {
                    enableMouseTracking: false,
                },
            },
            yAxis: [
                {
                    min: 0,
                    max: 4,
                },
                {
                    min: 0,
                    max: 4,
                },
            ],
            series: [
                {
                    type: "column",
                    name: "",
                    data: array,
                    yAxis: 0,
                },
                {
                    type: "line",
                    name: "Referência",
                    data: benchmark,
                },
            ],
        };
    }

    useEffect(() => {
        if (cyrcleStorage === 2) {
            updateToken()
                .then(() => configeToken())
                .then((response) => {
                    const headers = {
                        Authorization: `Bearer ${localStorage.getItem(
                            "@token"
                        )}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    };
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}/tema/5`,
                        headers,
                    })
                        .then((response) => {
                            setDataTema1(response.data.data);
                            setDataSubthemes1(response.data.data.subthemes);
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/tema/6`,
                                headers,
                            }).then((response) => {
                                setDataTema2(response.data.data);
                                setDataSubthemes2(response.data.data.subthemes);
                            });
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/tema/7`,
                                headers,
                            }).then((response) => {
                                setDataTema3(response.data.data);
                                setDataSubthemes3(response.data.data.subthemes);
                            });
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/tema/8`,
                                headers,
                            }).then((response) => {
                                setDataTema4(response.data.data);
                                setDataSubthemes4(response.data.data.subthemes);
                            });
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/pesquisa/2/relatorio`,
                                headers,
                            })
                                .then((response) => {
                                    setStageCompany(response.data.data.stage);
                                    setDataTemasRelatorio(response.data.data);
                                    setOptions(
                                        setOptionsChart(
                                            response.data.data.subthemes
                                        )
                                    );
                                    setShowLoading(true);
                                })
                                .catch(() => navigate("/"));
                        })
                        .catch(() => {
                            logoutLogin();
                        });
                });
        } else {
            updateToken()
                .then(() => configeToken())
                .then((response) => {
                    const headers = {
                        Authorization: `Bearer ${localStorage.getItem(
                            "@token"
                        )}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    };
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}/tema/1`,
                        headers,
                    })
                        .then((response) => {
                            setDataTema1(response.data.data);
                            setDataSubthemes1(response.data.data.subthemes);
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/tema/2`,
                                headers,
                            }).then((response) => {
                                setDataTema2(response.data.data);
                                setDataSubthemes2(response.data.data.subthemes);
                            });
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/tema/3`,
                                headers,
                            }).then((response) => {
                                setDataTema3(response.data.data);
                                setDataSubthemes3(response.data.data.subthemes);
                            });
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/tema/4`,
                                headers,
                            }).then((response) => {
                                setDataTema4(response.data.data);
                                setDataSubthemes4(response.data.data.subthemes);
                            });
                        })
                        .then(() => {
                            axios({
                                method: "get",
                                url: `${process.env.REACT_APP_API_URL}/pesquisa/1/relatorio`,
                                headers,
                            })
                                .then((response) => {
                                    setStageCompany(response.data.data.stage);
                                    setDataTemasRelatorio(response.data.data);
                                    setOptions(
                                        setOptionsChart(
                                            response.data.data.subthemes
                                        )
                                    );
                                    setShowLoading(true);
                                })
                                .catch((res) => {
                                    setShowLoading(true);
                                    setDataTemasRelatorio(false);
                                    navigate("/");
                                });
                        })
                        .catch(() => {
                            logoutLogin();
                        });
                });
        }
    }, []);

    return (
        <div
            className="container-relatorio-conjunto"
            id="imprimir"
            ref={printRef}
        >
            {!showLoading && <Loading />}

            {!!dataTemasRelatorio && (
                <>
                    <img
                        className="logo-abrafati-print"
                        src={logoAbrafati}
                        alt="logo da abrafati"
                    />

                    <h2>Ciclo {cyrcleStorage == 1 ? "2022" : "2023"}</h2>

                    <PageResultadoTema
                        title={dataTema1.title}
                        subthemes={dataSubthemes1}
                        idTema={dataTema1.id}
                    />
                    <PageResultadoTema
                        title={dataTema2.title}
                        subthemes={dataSubthemes2}
                        idTema={dataTema2.id}
                    />
                    <PageResultadoTema
                        title={dataTema3.title}
                        subthemes={dataSubthemes3}
                        idTema={dataTema3.id}
                    />
                    <PageResultadoTema
                        title={dataTema4.title}
                        subthemes={dataSubthemes4}
                        idTema={dataTema4.id}
                    />

                    <div className="container-relatorio">
                        <div className="main-relatorio" id="imprimir">
                            <h2 className="title">
                                Sistema de Avaliação da Sustentabilidade -
                                ABRAFATI
                            </h2>

                            <p className="relatorio__description">
                                O resultado do Sistema de Avaliação da
                                Sustentabilidade, demonstrado a seguir,
                                permitirá compreender o estágio de maturidade da
                                empresa e indicar as questões mais importantes
                                para a construção de uma jornada contínua e a
                                evolução do setor em termos de seu
                                desenvolvimento de programas, procedimentos e
                                soluções voltados à sustentabilidade.
                            </p>

                            <div className="relatorio__container-results">
                                <div className="relatorio__box-description">
                                    <h4>
                                        Resultado Geral da empresa:{" "}
                                        {`${nameUser}`}
                                    </h4>
                                    <p>
                                        Abaixo o resultado geral com avaliação
                                        do grau de maturidade da empresa,
                                        segundo as práticas de sustentabilidade
                                        e compromissos para o setor:
                                    </p>
                                    <br />
                                </div>

                                <CardRelatorio
                                    key={id}
                                    percent={Math.round(percent)}
                                    estagio={name}
                                    description={description}
                                    descriptionStage={title}
                                    score={score}
                                    percent_attendance={percent_attendance}
                                    banchmark={banchmark}
                                    banchmark_percent_attendance={
                                        banchmark_percent_attendance
                                    }
                                    sector={sector}
                                    total_score={total_score}
                                    idColor={`color-${id}`}
                                    bgColor={`bg-${id}`}
                                />
                            </div>

                            <div className="box-relatorio-subtema">
                                <h4>Resultado por Subtema</h4>
                                <p>
                                    Abaixo o resultado de avaliação por subtema,
                                    representando as práticas empresariais de
                                    sustentabilidade estabelecidas em medida de
                                    escala e o comparativo com resultado
                                    referência benchmark:
                                </p>
                            </div>

                            <div className="container-graph">
                                <HighchartsReact
                                    containerProps={{ className: "test-class" }}
                                    highcharts={Highcharts}
                                    options={options}
                                />

                                <img
                                    src={imgGraph}
                                    alt="ilustracao temas da pesquisa organizado em formato circular"
                                />
                            </div>

                            <div className="box-relatorio-congratulations">
                                <h4>Agradecemos a sua participação!</h4>

                                <p>
                                    Continue evoluindo na promoção dos temas
                                    voltados à sustentabilidade. Contribua com
                                    sua experiência e participe de forma ativa
                                    na construção de soluções para o setor de
                                    tintas e vernizes.
                                </p>
                            </div>
                        </div>
                    </div>

                    <button
                        className="btn-print no-print"
                        onClick={() => {
                            window.print();
                        }}
                    >
                        Imprimir relatório
                    </button>
                </>
            )}
        </div>
    );
}

export default RelatorioConjunto;

