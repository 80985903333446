import React, { useContext, useEffect, useRef } from "react";
import "./_cardQuestionResultado.scss";
import { ManagerProgressContext } from "../../contexts/managerProgres";
import InputQuestionResultado from "../inputQuestionResultado/inputQuestionResultado";

function CardQuestionResultado({ title, options, subTemaId, idForm, answer, idTema}){
  const { managerBtn } = useContext(ManagerProgressContext)
  let historyInputChosen = answer
  const containerQuestionRef = useRef(null);
  const clickShowRef = useRef(null);
   
  useEffect(() => {
    const inputSelector = document.querySelectorAll(
      `input[name='${subTemaId}']`
    );
    
    
    if(historyInputChosen === null || historyInputChosen === undefined) return

    inputSelector.forEach((el, index) => {    
      if(el.value <= historyInputChosen){
        el.classList.add(`input-onClick${idTema}`)
      }

      if(Number(el.value) === historyInputChosen){
        el.setAttribute('checked', '')
      } 

      if(managerBtn === false){
        el.disabled = true
      }
    })

    let statusQuestionAswered = historyInputChosen && true 

    // if(statusQuestionAswered){ 
    //   clickShowRef.current.style.borderLeft = '4px solid var(--color-tema)';
    // }else{
    //   clickShowRef.current.style.borderLeft = 'none'
    // }

  },[historyInputChosen, subTemaId])


  return (
    <div ref={containerQuestionRef} className={`container-card-questionarioResultado ${subTemaId}`} >
      <div onClick={() => console.log(options)} ref={clickShowRef} className="box-show-question">
        <p>{title}</p>
      </div>

      <form id={`form-${idForm-1}`}>
        {options.map((el) => (
          <InputQuestionResultado
            idTema={idTema}
            key={el.id}
            label={el.title}
            id={el.id}
            subTemaId={subTemaId}
            order={el.order}
          />
        ))}
      </form>
    </div>
  );
}
export default CardQuestionResultado;
