import { Navigate, Route, Routes } from "react-router-dom";
import Cadastro2 from "../pages/cadastro-2/cadastro-2";
import Cadastro3 from "../pages/cadastro-3/cadastro-3";
import Cadastro from "../pages/cadastro/cadastro";
import Login from "../pages/layout-login/Login";
import RenewPassword from "../pages/renewPassword/renewPassWord";

function AuthRoutes(){
    return(
        <>
            <Routes>
                <Route exact path="/" element={<Login/>}/>
                <Route exact path="/cadastro" element={<Cadastro/>}/>
                <Route exact path="/cadastro2" element={<Cadastro2/>} />
                <Route exact path="/cadastro3" element={<Cadastro3/>}/>
                <Route exact path="/renovarsenha" element={<RenewPassword/>}/>

                <Route exact path="/politica-de-privacidade" element={<Navigate to='/' replace/>}/>
                <Route exact path="/questionario/:id" element={<Navigate to='/' replace/>}/>
                <Route exact path="/relatorio" element={<Navigate to='/' replace/>}/>
                <Route exact path="/manual" element={<Navigate to='/' replace/>}/>
                <Route exact path="/downloads" element={<Navigate to='/' replace/>}/> 
                <Route exact path="/relatorio-conjunto" element={<Navigate to='/' replace/>}/> 
                <Route exact path="/arquivos/:id/:name" element={<Navigate to='/' replace/>}/>
            </Routes> 
        </>
    )
}

export default AuthRoutes;