import "./_cadastro-2.scss";
import logoAbrafati from "../../assets/logo-abrafati.png";
import Input from "../../components/input/Input";
import Button from "../../components/button/button";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DataRegistrationContext } from "../../contexts/contexts";

function Cadastro2() {
    const navigate = useNavigate();
    const brazilianStates = [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
    ];
    const [rgxValidatePhone, setRgxValidatePhone] = useState(false);
    const { company, setCompany, phone, setPhone, state, setState } =
        useContext(DataRegistrationContext);

    useEffect(() => {
        setRgxValidatePhone(phone);
    }, []);

    function errorValueInput(index) {
        let spanRequired = document.querySelectorAll(".required");
        spanRequired[index].style.display = "block";
    }

    function validateValueInput(index) {
        let spanRequired = document.querySelectorAll(".required");
        spanRequired[index].style.display = "none";
    }

    function validadeName() {
        let nameEmpresa = document.querySelector("#empresa");
        setCompany(nameEmpresa.value);
        if (nameEmpresa.value.length === "" || nameEmpresa.value.length < 3) {
            errorValueInput(0);
        } else {
            validateValueInput(0);
        }
    }

    function validatePhone() {
        let alertPhone = document.querySelector(".alert-erro-phone");
        let phoneInput = document.querySelector("#telefone");
        let input = phoneInput;
        setPhone(phoneMask(input.value));
        var regexValidate = /^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/;

        if (regexValidate.test(phone)) {
            alertPhone.style.display = "none";
        } else {
            alertPhone.style.display = "block";
        }

        setRgxValidatePhone(regexValidate.test(phone));
    }

    const phoneMask = (value) => {
        if (!value) return "";
        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d)/, "($1) $2");
        value = value.replace(/(\d)(\d{4})$/, "$1-$2");
        return value;
    };

    function onchangeInputState(e) {
        let alertState = document.querySelector(".alert-erro-state");
        setState(e.target.value);

        if (e.target.value !== "") {
            alertState.style.display = "none";
        }
    }

    function sendRegistrationData() {
        let alertState = document.querySelector(".alert-erro-state");
        validadeName();
        validatePhone();
        if (state === "") {
            alertState.style.display = "block";
        }
        if (state === "") return;
        if (company.length < 2) return;
        if (rgxValidatePhone === false) return;

        navigate("/cadastro3");
    }

    return (
        <section className="container-cadastro2">
            <div className="login__box-left">
                <h2 className="title">Cadastro</h2>

                <form className='cadastro__form'>
                    <Input
                        onChange={validadeName}
                        value={company}
                        text="É necessário registar um nome!"
                        type="text"
                        id="empresa"
                        label="Empresa"
                        placeholder="Digite o nome da sua empresa"
                    />

                    <select
                        className="container-select-state"
                        onChange={(e) => onchangeInputState(e)}
                        value={state}
                        defaultValue="0"
                        name="estado"
                        id="estado"
                    >
                        <option value="" selected disabled>
                            Selecione uma opção
                        </option>
                        {brazilianStates.map((el) => (
                            <option key={el} value={el}>
                                {el}
                            </option>
                        ))}
                    </select>
                    <p className="alert-erro-state">
                        É necessário selecionar um estado
                    </p>

                    <Input
                        onChange={validatePhone}
                        value={phone}
                        type="tel"
                        maxLength="15"
                        id="telefone"
                        label="Telefone"
                        placeholder="Digite seu telefone"
                    />
                    <p className="alert-erro-phone">
                        Digite um telefone válido, com DDD da operadora e seu
                        número!
                    </p>
                    <Button onClick={sendRegistrationData} type="button">
                        Avançar
                    </Button>
                </form>

                <Link className="btn-back" to="/cadastro">
                    Voltar
                </Link>

                <img
                    className="logo-abrafati"
                    src={logoAbrafati}
                    alt="logomarca Abrafati"
                />
            </div>
        </section>
    );
}

export default Cadastro2;
