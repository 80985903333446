import './_cadastro.scss';
import logoAbrafati from '../../assets/logo-abrafati.png';
import Input from '../../components/input/Input';
import Button from '../../components/button/button';
import { useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useContext } from 'react';
import { DataRegistrationContext } from '../../contexts/contexts';
import { Link, useNavigate } from 'react-router-dom';


function Cadastro(){
  const navigate = useNavigate()
  const { email, setEmail, name, setName, password, setPassword, password_confirmation, setPassword_confirmation} = useContext(DataRegistrationContext)
  
  useEffect(() => {
    document.title = 'Abrafati - Cadastro'

  }, [])
  
  function errorValueInput(index){
    let spanRequired = document.querySelectorAll('.required')
    spanRequired[index].style.display = "block";
  }

  function validateValueInput(index){
    let spanRequired = document.querySelectorAll('.required')
    spanRequired[index].style.display = "none";
  }
  

  function validadeName(){
    let nameUser = document.querySelector('#nome')
    setName(nameUser.value)

    if(nameUser.value.length === '' || nameUser.value.length < 3){
      errorValueInput(0)
    }else{
      validateValueInput(0)
    }
  }

  function validadeEmail(){
    let emailUser = document.querySelector('#email')
    setEmail(emailUser.value)

    const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    if(emailUser.value.length === '' || !emailRegex.test(emailUser.value)){
      errorValueInput(1)
    }else{
      validateValueInput(1)
    }
  }

  function validadePassword(){
    let password = document.querySelector('#senha')
    setPassword(password.value)

    if(password.value.length === '' || password.value.length < 6){
      errorValueInput(2)
    }else{
      validateValueInput(2)
    }

    actionVisibleOnchange(password.value)
  }

  function verifyPassWord(){
    let passwordConfirmed = document.querySelector("#senha2")
    setPassword_confirmation(passwordConfirmed.value)

    if(password !== passwordConfirmed.value || passwordConfirmed.value === ''){
      errorValueInput(3)
      
    }else{
      validateValueInput(3)
    }

    actionVisibleOnchange2(passwordConfirmed.value)
  }


  function sendRegistrationData(){
    let emailUser = document.querySelector('#email')
    validadeName()
    validadeEmail()
    validadePassword()
    verifyPassWord()
    if(name.length < 3) return
    if(password.length <= 0 & password_confirmation.length <= 0) return
    if(password !== password_confirmation) return
    if(emailUser.value === '') return

    navigate('/cadastro2')
  }


  const [usePassword, setUsePassword] = useState('password')
  const [usePassword2, setUsePassword2] = useState('password')
  const [visibleOnchange, setVisibleOnchange] = useState(false)
  const [visibleOnchange2, setVisibleOnchange2] = useState(false)
  
  function actionVisibleOnchange(password){
    if(password.length > 0){
      setVisibleOnchange(true)
    }else{
      setVisibleOnchange(false)
    }
  }

  function actionVisibleOnchange2(passwordConfirmation){
    if(passwordConfirmation.length > 0){
      setVisibleOnchange2(true)
    }else{
      setVisibleOnchange2(false)
    }
  }

  function visiblePassword(){
    const password = document.querySelector('#senha')
    if(password.type === 'password'){
      password.type = 'text'
      setUsePassword('text')
    }else{
      password.type = 'password'
      setUsePassword('password')
    }   
  }
  function visiblePassword2(){
      const password2 = document.querySelector('#senha2')
      if(password2.type === 'password'){
        password2.type = 'text'
        setUsePassword2('text')
      }else{
        password2.type = 'password'
        setUsePassword2('password')
      }
    }


    return(
        <section className="container-cadastro">
            {/* <div className='container-img' >
              <img className='img-wallpaper' src={bgLogin} alt='wallpaper natureza'/>
            </div> */}
            <div className="login__box-left">
              <div className='container-form'>
                <h2 className="title">
                    Cadastro
                </h2>

                <form className='cadastro__form'>
                  <Input onChange={validadeName} value={name} text='O nome deve ter no mínimo 3 caracteres!' type='text' id="nome" label="Nome" placeholder="Digite seu nome"/>
                  
                  <Input onChange={e => validadeEmail(e)} value={email} text='É necessário digitar um email válido!' type='email' id="email" label="E-mail" placeholder="Digite seu e-mail"/>

                  <Input onChange={validadePassword} value={password} text='A sua senha deve ter no mínimo 6 caracteres!' type='password' minLength='6' id="senha" label="Senha" placeholder="Digite sua senha"/>
                  { usePassword === 'text' && visibleOnchange && <AiOutlineEye onClick={visiblePassword} className='logo-invisible'/> }
                  { usePassword === 'password' && visibleOnchange && <AiOutlineEyeInvisible onClick={visiblePassword} className='logo-invisible'/>}

                  <Input onChange={verifyPassWord} value={password_confirmation} type='password' minLength='' id="senha2" label="Confirmar senha" placeholder="Digite sua senha" text={'As senhas cadastradas não conferem!'}/>
                  { usePassword2 === 'text' && visibleOnchange2 && <AiOutlineEye onClick={visiblePassword2} className='logo-invisible2'/>}
                  { usePassword2 === 'password' && visibleOnchange2 && <AiOutlineEyeInvisible onClick={visiblePassword2} className='logo-invisible2'/>}

                  <Button onClick={sendRegistrationData} type='button'>Avançar</Button>
                  <Link className='btn-back' to='/'>Voltar</Link>
                </form>

                <img className='logo-abrafati' src={logoAbrafati} alt='logomarca Abrafati' />
              </div>
            </div>
            
        </section>
    )
}

export default Cadastro;