import CardQuestionResultado from '../cardQuestionResultado/cardQuestionResultado';
import './_cardPerguntasResultado.scss';


function CardPerguntasResultado({title, questions, id, idTema}){
    

    return(
        <div className="container-perguntas-resultado">
            <h4 className={`perguntas-resultado__title title-${id} color-tema${idTema}`}>{title}</h4>
            
            {
                questions.map(el => <CardQuestionResultado key={el.id} subTemaId={el.id} idForm={el.id} idTema={idTema} title={el.title} options={el.options} answer={el.answer}/>)
            }

        </div>
    )
}

export default CardPerguntasResultado;