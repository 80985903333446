import { useContext, useEffect, useLayoutEffect, useState } from "react";
import CardPopUp from "../../components/cardPopUp/cardPopUp";
import CardRelatorio from "../../components/cardRelatorio/cardRelatorio";
import { AuthContext } from "../../contexts/auth";
import "./_relatorio.scss";
import imgGraph from "../../assets/bg-grafico22__3_.png";
import logoAbrafati from "../../assets/logo-abrafati.png";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import Loading from "../../components/loading/loading";
import axios from "axios";

HighchartsMore(Highcharts);

function Relatorio() {
    const { updateToken, cyrcleStorage } = useContext(AuthContext);
    const nameUser = JSON.parse(localStorage.getItem("@user")).company_data
        ?.company;
    const [showPopUp, setShowPopUp] = useState(false);
    const [options, setOptions] = useState([]);
    const [widthBom, setWidthBom] = useState(0);
    const [data, setData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const headers = {
            Authorization: `Bearer ${localStorage.getItem("@token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        };
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}/pesquisa/${cyrcleStorage}/relatorio`,
            headers,
        })
            .then((res) => {
                setOptions(setOptionsChart(res?.data.data.subthemes));
                setData(res);
                setIsLoading(false);
                setShowPopUp(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShowPopUp(true);
                setData(false);
            });
    }, [cyrcleStorage]);

    window.addEventListener("scroll", () => {
        setWidthBom(window?.innerWidth);

        let mySVG = document.querySelector(".highcharts-root");
        if (mySVG === null) return;

        if (widthBom > 1440) {
            mySVG.setAttribute("viewBox", "0 0 800 600");
        } else {
            mySVG.setAttribute("viewBox", "0 0 700 600");
        }
    });

    useLayoutEffect(() => {
        setWidthBom(window?.innerWidth);

        let mySVG = document.querySelector(".highcharts-root");
        if (mySVG === null) return;

        if (widthBom > 1440) {
            mySVG.setAttribute("viewBox", "0 0 800 600");
        } else {
            mySVG.setAttribute("viewBox", "0 0 700 600");
        }
    });

    useEffect(() => {
        setWidthBom(window?.innerWidth);

        let mySVG = document.querySelector(".highcharts-root");
        if (mySVG === null) return;

        if (Number(window.innerWidth) > 1440) {
            mySVG.setAttribute("viewBox", "0 0 800 600");
        } else {
            mySVG.setAttribute("viewBox", "0 0 700 600");
        }
    }, [widthBom]);

    useEffect(() => {
        document.title = "Abrafati - Relatório";
        window.scroll({ top: 0 });
        updateToken();
    }, []);

    function getLegend(value) {
        if (value < 21.17) {
            return "Cadeia de fornecimento sustentável &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        } else if (value < 21.17 * 2) {
            return "Comunidade e&nbsp;sociedade";
        } else if (value < 21.16 * 3) {
            return "Relacionamento com&nbsp;revendores e&nbsp;profissionais de&nbsp;pintura";
        } else if (value < 21.17 * 4) {
            return "Transparência e informação para o consumidor";
        } else if (value < 21.16 * 5) {
            return "Gestão de Resíduos Industriais e Materiais Perigosos";
        } else if (value < 21.16 * 6) {
            return "Qualidade do Ar e Emissões";
        } else if (value < 21.16 * 7) {
            return "Energia";
        } else if (value < 21.16 * 8) {
            return "Água e Efluentes";
        } else if (value < 21.16 * 9) {
            return "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Uso eficiente de materiais e gestão do ciclo de vida";
        } else if (value < 21.16 * 10) {
            return "Desenvolvimento, educação e fortalecimento para a ciência";
        } else if (value < 21.16 * 11) {
            return "Diversidade e Inclusão";
        } else if (value < 21.16 * 12) {
            return "Saúde, Segurança e Qualidade de Vida do Trabalhador";
        } else if (value < 21.16 * 13) {
            return "Relações Trabalhistas";
        } else if (value < 21.16 * 14) {
            return "Governança Corporativa";
        } else if (value < 21.16 * 15) {
            return "Ética e Integridade";
        } else if (value < 21.16 * 16) {
            return "Modelo de negócios e Inovação";
        } else {
            return "Saúde e Segurança do Consumidor  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
        }
    }

    function setOptionsChart(subthemes) {
        let array = [];
        let benchmark = [];

        subthemes?.map((subtheme) => {
            array.push({ y: subtheme.value, color: subtheme.color });
            benchmark.push({
                y: subtheme.benchmark,
                color: subtheme.color,
            });
        });

        return {
            chart: {
                polar: true,
                width: 700,
                height: 600,
                backgroundColor: "transparent",
            },
            title: {
                text: "Highcharts Polar Chart",
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                size: "90%",
            },
            tooltip: {
                formatter: function (tooltip) {
                    return subthemes[this.point.index].stage.description;
                },
                // enabled: false
            },
            xAxis: {
                tickInterval: 21.17,
                min: 0,
                max: 360,
                labels: {
                    enabled: true,
                    formatter: function () {
                        return getLegend(this.value);
                    },
                },
            },
            plotOptions: {
                series: {
                    pointStart: 0,
                    pointInterval: 21.17,
                },
                column: {
                    pointPadding: 0,
                    groupPadding: 0,
                },
                line: {
                    enableMouseTracking: false,
                },
            },
            yAxis: [
                {
                    min: 0,
                    max: 4,
                },
                {
                    min: 0,
                    max: 4,
                },
            ],
            series: [
                {
                    type: "column",
                    name: "",
                    data: array,
                    yAxis: 0,
                },
                {
                    type: "line",
                    name: "Referência",
                    data: benchmark,
                },
            ],
        };
    }

    return (
        <div className="container-relatorio">
            {showPopUp && <CardPopUp />}
            {isLoading && <Loading />}

            {!!data && (
                <div className="main-relatorio" id="imprimir">
                    <img
                        className="logo-abrafati-print"
                        src={logoAbrafati}
                        alt="logo da ABRAFATI"
                    />
                    <h2 className="title">
                        Sistema de Avaliação da Sustentabilidade - ABRAFATI{" "}
                        <strong>{cyrcleStorage == 1 ? "2022" : "2023"}</strong>
                    </h2>

                    <p className="relatorio__description">
                        O resultado do Sistema de Avaliação da Sustentabilidade,
                        demonstrado a seguir, permitirá compreender o estágio de
                        maturidade da empresa e indicar as questões mais
                        importantes para a construção de uma jornada contínua e
                        a evolução do setor em termos de seu desenvolvimento de
                        programas, procedimentos e soluções voltados à
                        sustentabilidade.
                    </p>

                    <div className="relatorio__container-results">
                        <div className="relatorio__box-description">
                            <h4>Resultado Geral da empresa: {`${nameUser}`}</h4>
                            <p>
                                Abaixo o resultado geral com avaliação do grau
                                de maturidade da empresa, segundo as práticas de
                                sustentabilidade e compromissos para o setor:
                            </p>
                            <br />
                        </div>
                        <CardRelatorio
                            key={data?.data.data.stage.id}
                            percent={Math.round(data?.data.data.percent)}
                            estagio={data?.data.data.stage.name}
                            description={data?.data.data.stage.description}
                            descriptionStage={data?.data.data.stage.title}
                            score={data?.data.data.score}
                            percent_attendance={
                                data?.data.data.percent_attendance
                            }
                            banchmark={data?.data.data.banchmark}
                            banchmark_percent_attendance={
                                data?.data.data.banchmark_percent_attendance
                            }
                            sector={data?.data.data.sector}
                            total_score={data?.data.data.total_score}
                            idColor={`color-${data?.data.data.stage.id}`}
                            bgColor={`bg-${data?.data.data.stage.id}`}
                        />
                    </div>

                    <div className="box-relatorio-subtema">
                        <h4>Resultado por Subtema</h4>

                        <p>
                            Abaixo o resultado de avaliação por subtema,
                            representando as práticas empresariais de
                            sustentabilidade estabelecidas em medida de escala e
                            o comparativo com resultado referência benchmark:
                        </p>
                    </div>

                    <div className="container-graph">
                        <HighchartsReact
                            containerProps={{ className: "test-class" }}
                            highcharts={Highcharts}
                            options={options}
                        />

                        <img
                            src={imgGraph}
                            alt="ilustracao temas da pesquisa organizado em formato circular"
                        />
                    </div>

                    <h3 className="container-graph__legenda-explicativa">
                        Legenda: <br />{" "}
                        <p>
                            <span>
                                <span className="ponto-contrast">.</span>....
                                <span className="ponto-contrast">.</span>
                            </span>{" "}
                            Resultado benchmark
                        </p>
                    </h3>

                    <div className="box-relatorio-congratulations">
                        <h4>Agradecemos a sua participação!</h4>

                        <p>
                            Continue evoluindo na promoção dos temas voltados à
                            sustentabilidade. Contribua com sua experiência e
                            participe de forma ativa na construção de soluções
                            para o setor de tintas e vernizes.
                        </p>
                    </div>

                    <div className="relatorio__box-btns no-print">
                        <span
                            onClick={() => window.print()}
                            className="btn-relatorio"
                            href=""
                        >
                            Imprimir
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Relatorio;

