import axios from "axios";


function configeToken(){
    axios.interceptors.request.use(function (request) {
        const token = localStorage.getItem('@token')
    
        if(token){
            request.headers.Authorization = `Bearer ${token}`
        }
    
        return request;
    }, function (error){
    
        return Promise.reject(error);
    });
}

export default configeToken;
