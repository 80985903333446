import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ContextRegistrationProvider from './contexts/contexts';
import AuthContextProvider from './contexts/auth';
import Routes from './routes';
import AdminContextProvider from './contexts/temasAdminContext';
import ManagerProgressContextProvider from './contexts/managerProgres';
import { queryRelatorioData } from './services/queryRelatorio';
import { QueryClientProvider } from 'react-query';

function App() {

  return (
    <QueryClientProvider client={queryRelatorioData}>
      <ManagerProgressContextProvider>
        <AdminContextProvider>
          <AuthContextProvider>
            <ContextRegistrationProvider>
              <BrowserRouter>
                <Routes/>
              </BrowserRouter>
            </ContextRegistrationProvider>
          </AuthContextProvider>
        </AdminContextProvider>
      </ManagerProgressContextProvider>
    </QueryClientProvider>


  );
}

export default App;
