import React from "react";
import Fechar from '../../assets/fechar.svg'
import './_popup.scss'

function popup(props) {
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <button className="close-btn" onClick={() => props.setTrigger(false)}>
                    <img src={Fechar} alt="" />
                </button>
                { props.children }
            </div>
        </div>
    ) : "";
}

export default popup;
