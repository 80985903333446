import './_cardManual.scss';
import manual from '../../assets/manualCard.png';
import manualPdf from '../../assets/manual-pt.pdf';
import manualPdfEn from '../../assets/manual-en.pdf';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
function CardManual({title, src, hrefCard, classBtn, titleBtn, id}){
    return(
        <div className="card-manual">
            <img src={src} alt={`imagem que representa: ${title}`}/>
            <h4>{title}</h4>
            
            {
                classBtn ? 
                <Link to={`/arquivos/${id}/${title}`}>
                    <button className={classBtn || 'btn-manual'}>Acessar arquivos</button>
                </Link> : (
                    <>
                        <a href={hrefCard} download="manual-pt-br" type="application/pdf" 
                        className={classBtn || 'btn-manual'}>
                            Download - PT
                        </a>

                        <a href={manualPdfEn} download="manual-en" type="application/pdf" 
                        className={classBtn || 'btn-manual'}>
                            Download - EN
                        </a>
                    </>
                )
            }
        </div>
    )
}
export default CardManual;
CardManual.propTypes = {
    title: PropTypes.isRequired
}

CardManual.defaultProps = {
    title: 'Manual de preenchimento - 2023',
    src: manual,
    hrefCard: manualPdf,
}