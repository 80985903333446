import { GrDocumentPdf } from 'react-icons/gr'
import "./_cardDownloadArchive.scss";

function CardDownloadArchive(props){
    return(
        <div className="box-card-archive">
            <GrDocumentPdf/>

            <p>{props.title}</p>

            <a href={props.hrefArchive} target='blank' download='download' 
            type="application/pdf" className='btn-archive'>
                Download
            </a>

        </div>
    )
}

export default CardDownloadArchive;