import "./_renewPassword.scss";
import bgLogin from "../../assets/bg-login.jpg";
import logoAbrafati from "../../assets/logo-abrafati.png";
import Input from "../../components/input/Input.jsx";
import Button from "../../components/button/button";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function RenewPassword() {
    const navigate = useNavigate();
    const senhaRef = useRef(null);
    const passwordConfirmationRef = useRef(null);
    const urlParams = new URLSearchParams(window.location.search);
    const [emailParams] = useState(urlParams.get("email"));
    const [tokenParams] = useState(urlParams.get("token"));
    const [messagePostPassword, setMessagePostPassword] = useState("");
    const [success, setSuccess] = useState(false);

    function messagePostNewPassword(messagePostPassword) {
        let spanRequired = document.querySelectorAll(".required");
        spanRequired[2].style.display = "block";
        spanRequired[2].style.color = "#5B97DB";
    }

    function errorValueInput(index) {
        let spanRequired = document.querySelectorAll(".required");
        spanRequired[index].style.display = "block";
    }

    function sucessValueInput(index) {
        let spanRequired = document.querySelectorAll(".required");
        spanRequired[index].style.display = "none";
    }

    function validatePassword() {
        if (senhaRef.current.value.length < 6) {
            errorValueInput(1);
        } else {
            sucessValueInput(1);
        }
    }

    function errorValueInputPassworConfirm(index) {
        let spanRequired = document.querySelectorAll("input[type='text']");
        spanRequired[index].style.borderColor = "red";
    }

    function sucessValueInputPassworConfirm(index) {
        let spanRequired = document.querySelectorAll("input[type='text']");
        spanRequired[index].style.borderColor = "#ECECEC";
    }

    function validatePasswordConfirm() {
        if (senhaRef.current.value !== passwordConfirmationRef.current.value) {
            errorValueInputPassworConfirm(2);
        } else {
            sucessValueInputPassworConfirm(2);
        }
    }

    function postNewPassword() {
        if (senhaRef.current.value.length < 6) return;
        if (senhaRef.current.value !== passwordConfirmationRef.current.value)
            return;

        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        };

        let body = {
            email: `${emailParams}`,
            password: `${senhaRef.current.value}`,
            password_confirmation: `${passwordConfirmationRef.current.value}`,
            token: `${tokenParams}`,
        };

        fetch(`${process.env.REACT_APP_API_URL}/redefinir-senha`, {
            method: "POST",
            headers,
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                setMessagePostPassword(data.message);
                setSuccess(true);
                senhaRef.current.value = null;
                passwordConfirmationRef.current.value = null;
            });

        messagePostNewPassword(messagePostPassword);
    }

    function redirectLogin() {
        navigate("/");
    }

    return (
        <section className="container-renew-password">
            <img
                className="img-wallpaper"
                src={bgLogin}
                alt="wallpaper natureza"
            />

            <div className="renew-password__form-renew">
                <div className="box-apresentation">
                    <h2 className="title">Seja bem vindo</h2>
                    <p className="subtitle">Registre sua nova senha.</p>
                </div>

                <form>
                    <Input
                        value={emailParams}
                        key="email"
                        type="text"
                        id="email"
                        label="E-mail"
                        placeholder="Digite seu e-mail"
                    />
                    <Input
                        useRef={senhaRef}
                        onChange={validatePassword}
                        key="senha"
                        text="A senha deve ser superior a 6 digitos!"
                        type="text"
                        id="senha"
                        label="Nova senha"
                        placeholder="Digite sua nova senha"
                    />
                    <Input
                        useRef={passwordConfirmationRef}
                        onChange={validatePasswordConfirm}
                        text={messagePostPassword}
                        type="text"
                        key="senhaConfirm"
                        id="senhaConfirm"
                        label="Confirmar senha"
                    />

                    {!success ? (
                        <>
                            <Button
                                onClick={postNewPassword}
                                type="button"
                                className="renew-password__btn"
                            >
                                Enviar
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={redirectLogin}
                                type="button"
                                className="renew-password__btn"
                            >
                                Fazer login
                            </Button>
                        </>
                    )}

                    <img
                        className="logo-abrafati"
                        src={logoAbrafati}
                        alt="logomarca Abrafati"
                    />
                </form>
            </div>
        </section>
    );
}

export default RenewPassword;