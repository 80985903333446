import './_input.scss';

function Input({type, useRef, id, label, onChange, placeholder, min, max, minLength, maxLength, text, value}){

    return(
        <div className='box-input'>
            <label htmlFor={id}>{label}</label>
            <input ref={useRef} type={type} value={value} minLength={minLength} maxLength={maxLength} id={id} placeholder={placeholder} onChange={onChange}/>
            <span className='required'>{text}</span>
        </div>
    )
}

export default Input;