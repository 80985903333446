import { useContext, useState } from "react";
import { createContext } from "react";
import { AuthContext } from "./auth";


export const DataRegistrationContext = createContext()

function ContextRegistrationProvider({children}){
    const [showPopEmail, setShowPopEmail] = useState(false)
    const [showPopAdmin, setShowPopAdmin] = useState(false)
    const {setUser} = useContext(AuthContext)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password_confirmation, setPassword_confirmation] = useState('')
    const [company, setCompany] = useState('')
    const [phone, setPhone] = useState('')
    const [state, setState] = useState('')
    const [number_of_employees, setNumber_of_employees] = useState('')
    const [company_type_id, setCompany_type_id] = useState('')
    const [type_capital_id, setType_capital_id] = useState('')
    const [annual_billing_id, setAnnual_billing_id] = useState('')

    function registrationClient(){
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
        };

        let body = {
            "user": {
                "name": name,
                "email": email,
                "password": password,
                "password_confirmation": password_confirmation
            },
            "company_data": {
                "company": company,
                "phone": phone,
                "state": state,
                "number_of_employees": number_of_employees,
                "terms": 1,
                "company_type_id": company_type_id,
                "capital_type_id": type_capital_id,
                "annual_billing_id": annual_billing_id
            }
        };

        fetch(`${process.env.REACT_APP_API_URL}/register`, {
            method: "POST",
            headers,
            body: JSON.stringify(body),
        })
        .then(response => {
            if(response.status === 422){
                setShowPopEmail(!showPopEmail)
            }
            if(response.status === 201){
                setShowPopAdmin(!showPopAdmin)
            }
            return response.json()
        })
        .then(data => {
            localStorage.setItem('@token', data.token.access_token)
            localStorage.setItem('@user', JSON.stringify(data.user))
            setUser(data.user)
        })
        .catch(error => {
            console.log(error)
        })
    }

    return(
        <DataRegistrationContext.Provider value={{registrationClient,
         email, setEmail, name, setName, password, setPassword, password_confirmation, setPassword_confirmation,
          company, setCompany, phone, setPhone, state, setState, company_type_id, setCompany_type_id,
          type_capital_id, setType_capital_id, annual_billing_id, setAnnual_billing_id,
          number_of_employees, setNumber_of_employees, showPopEmail, setShowPopEmail, showPopAdmin, setShowPopAdmin}}>
            {children}
        </DataRegistrationContext.Provider>
    )
}

export default ContextRegistrationProvider;
