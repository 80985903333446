import { useContext, useEffect } from 'react';
import { AdminContext } from '../../contexts/temasAdminContext';
import CardQuestion from '../cardQuestion/cardQuestion';
import './_cardPerguntas.scss';

function CardPerguntas({title, questions, postDataQuestion, id, lastQuestion, finishTema}){
    const {subTemaId} = useContext(AdminContext)

    useEffect(() => {
        if(subTemaId === `title-${id}`){
            let valueScroll = document.querySelector(`.${subTemaId}`).offsetTop

            window.scroll(0, valueScroll)
        }
    },[])

    return(
        <div className="container-card-perguntas">
            <h4 className={`card-perguntas-title title-${id}`}>{title}</h4>

            {
                questions.map(el => <CardQuestion finishTema={finishTema} key={el.id} lastQuestion={lastQuestion} subTemaId={el.id} idForm={el.id} title={el.title} options={el.options} answer={el.answer} postDataQuestion={postDataQuestion} />)
            }
        </div>
    )
}

export default CardPerguntas;
