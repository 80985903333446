import {MdChevronRight} from "react-icons/md";
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './_cardResumePerguntas.scss'


function CardResumePerguntas({image, title, progress, description, color, bg}){
    const progressBarRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        progressBarRef.current.style.background = `conic-gradient(#ffffff ${(100 - progress) * 3.6}deg, #2AD3AA ${(100 - progress) * 3.6}deg)`
    }, [progress])

    return(
        <>
            <div className="box-previous-page" onClick={() => navigate('/')} >
                <span className="icon-iteration">
                    <MdChevronRight/>
                </span>
                <h2 className='title-perguntas'>{title}</h2>
            </div>

            <div className="card-resumo-perguntas">
                    <div className="perguntas__box-left">
                        <h3>Cada subtema é composto por questões (indicadores), representando práticas empresariais 
                            estabelecidas em uma medida de escala. A opção de resposta deverá corresponder integralmente
                            aos requisitos do nível apontado e, para os casos de não atendimento ao estágio iniciante, 
                            selecionar a opção "Não atende".  Para aqueles indicadores onde o primeiro nível representa 
                            exclusivamente o cumprimento legal, não há a opção de seleção para “Não atende”.
                        </h3>

                        <div ref={progressBarRef} className="grafico-rosca-resposta">
                            <div className="value-progress">{progress}%</div>
                        </div>
                    </div>

                    <div className="perguntas__box-rigth">
                        <div className="box-img-perguntas">
                            <img src={image} alt=""/>
                        </div>

                        <p dangerouslySetInnerHTML={{__html: description}}>
                        </p>
                    </div>
                </div>

        </>
    )
}

export default CardResumePerguntas;