import axios from "axios";
import { createContext, useEffect, useState } from "react";
import configeToken from "../services/api";
import { AuthContext } from "./auth";
import { useContext } from "react";
export const ManagerProgressContext = createContext()

function ManagerProgressContextProvider({children}){
    const [dataTemas, setDataTemas] = useState([])
    const [allProgress, setAllProgress] = useState([])
    const [managerBtn, setManagerBtn] = useState(true)
    const [dataTemaAswered, setDataTemaAswered] = useState(true)

    useEffect(() => {
        const array = []

        for(let progress in dataTemas){
            array.push(Math.round(dataTemas[progress].progress))
        }
        setAllProgress(array)
        const validadeProgress = array.map(el => el === 100)
        const validateAll100 = validadeProgress.includes(false)
        setManagerBtn(validateAll100)

    }, [dataTemas])

    function getProgress(){
        const cyrcleStorage = JSON.parse(localStorage.getItem("@cycleChosed"))
        configeToken()

        const headers = {
            "Authorization": ``,
            "Content-Type": "application/json",
            "Accept": "application/json",
        };

        axios({method: "get", url:`${process.env.REACT_APP_API_URL}/pesquisa/${cyrcleStorage}`, headers})
        .then((response) => {
            setDataTemas(response.data.data.themes)
            setDataTemaAswered(response.data.data.themes[0].answered)
        })
    }

    return(
        <ManagerProgressContext.Provider value={{allProgress, getProgress, managerBtn, dataTemaAswered}}>
            {children}
        </ManagerProgressContext.Provider>
    )
}


export default ManagerProgressContextProvider;
