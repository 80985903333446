import './_manual.scss';
import CardManual from '../../components/cardManual/cardManual';
import {MdChevronRight} from "react-icons/md";
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';

function Manual(){
    const { updateToken } = useContext(AuthContext)
    const navigate = useNavigate()
    
    useEffect(() => {
        updateToken()

        document.title = 'Abrafati - Manual'
      }, [])

    return(
        <div className='container-manual'>           
            <main className='container-manual-main'>
                <div className="box-previous-page" onClick={() => navigate('/')} >
                    <span className="icon-iteration">
                        <MdChevronRight/>
                    </span>
                    <h2 className='title'>Manual de preenchimento</h2>
                </div>

                <div className='container-cards'>
                    <CardManual ano='2023'/>
                </div>
            </main>
        </div>
    )
}

export default Manual;