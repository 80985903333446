import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { MdChevronRight } from 'react-icons/md';
import axios from 'axios';
import './_arquivos.scss'
import CardDownloadArchive from '../../components/cardDownloadArchive copy/CardDownloadArchive';
import Loading from '../../components/loading/loading';

function Arquivos(){
    const [ cardsArchives, setCardsArchives ] = useState([])
    const [ renderLoading, setRenderLoading ] = useState(true)
    const { id, name } = useParams()
    const { updateToken } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        updateToken()
        document.title = 'Arquivos'

        const url = `${process.env.REACT_APP_API_URL}/downloads/paths/${id}`;

        axios({
            method: 'get',
            url: url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                setCardsArchives(response.data.data)
                setRenderLoading(false)
            })    
      }, [id])

    return(
        <section className='container-arquivos'>

            { renderLoading && <Loading/> }

            <main className='container-arquivos-main'>
                <div className="box-previous-page" onClick={() => navigate('/')} >
                    <span className="icon-iteration">
                        <MdChevronRight/>
                    </span>
                    <h2 className='title'>{name}</h2>
                </div>

                <div className='box-cards-archives'>

                    {
                        !cardsArchives ? <p>Não há arquivos para download disponíveis no momento!</p> :
                        <>

                        {
                            cardsArchives?.map((card, i) => {
                                return <CardDownloadArchive key={card.path} hrefArchive={card.path} title={card.nome}/>
                            })
                        }
                        </>
                    }
            
                </div>
            </main>
        </section>
    )
}

export default Arquivos;