import './_inputQuestion.scss';

function InputQuestion({label, id, subTemaId, activeSelection, order}){
    return(
        <div className="checks">
            <label>
              <input
                type="radio"
                name={subTemaId}
                id={id}
                value={id}
                onClick={e => activeSelection(e)}
                data-order={order}
              />
              <div className='label' dangerouslySetInnerHTML={{__html: label}} ></div>
            </label>
        </div>
    )
}

export default InputQuestion;