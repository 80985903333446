import './_inputQuestionResultado.scss';

function InputQuestionResultado({label, id, subTemaId, order, idTema}){

    return(
        <div className="checks-resultado">
            <label>
              <input
                className={`input-tema${idTema}`}
                type="radio"
                name={subTemaId}
                id={id}
                value={id}
                data-order={order}
              />
              <div className='label' dangerouslySetInnerHTML={{__html: label}} ></div>
            </label>
        </div>
    )
}

export default InputQuestionResultado;